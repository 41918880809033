import React from 'react'
import Carousel from '../../components/carousel/Carousel'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Adquirentes(){
    return (
        <React.Fragment>
            <Container fluid as="section" id="adquirentes">
                <Row id="title-row">
                    <Col>
                        <h1>adquirentes integradas</h1>
                    </Col>
                </Row>
                <Row id="carousel-row">
                    <Carousel/>
                </Row>
            </Container>
        </React.Fragment>
    )
}