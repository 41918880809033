import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Image from '../../static/quem-somos-img.jpg'
import Card from 'react-bootstrap/Card'

export default function QuemSomos(){
    return (
        <Container fluid as="section" id="quem-somos">
            <Row>
                <Col xl="6" md="12" sm="12" lg="6">
                    <h1>quem somos</h1>
                    <p>
                        A Statix é uma empresa de tecnologia e negócios, 
                        com uma abordagem que preza por pensar antes e 
                        além da solução, contribuindo para o crescimento de
                        nossos clientes através da inovação e de nossos 
                        resultados.
                    </p>
                    <p>
                        Temos o compromisso de prestar serviços de qualidade 
                        para nossos parceiros, pois entendemos que é o único 
                        caminho para o estabelecimento de relações ricas e 
                        duradouras, nosso principal objetivo.
                    </p>
                </Col>
                <Col id="col-img" xl="6" md="12" sm="12" lg="6">
                    <Card>
                        <Card.Img id="quem-somos-img" src={Image} alt="Statix Inovação e Tecnologia"/>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}