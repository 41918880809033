import React from "react";
import InputMask from "react-input-mask";
import { Formik, useFormik } from "formik";
import { FormSchema } from "./FormValidation";
import Axios from "axios";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import ModalFeedback from "../../components/modal/Modal";

export default function Formulario() {
  const [modalShow, setModalShow] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      nome: "",
      area: "",
      email: "",
      telefone: "",
      razaoSocial: "",
      mensagem: "",
    },
  });

  const submitFunction = (values) => {
    const config = {
      headers: {
        Authorization: `Token token=035510000020200608`, // token de servidor de produção
        // Authorization: 'Token token=0001ADMBR20150526' // token do servidor de homologação
      },
    };

    Axios.post(
      "https://www.gearman.com.br/monitor-ws/formulario-site-statix/encaminharPrincipal",
      values,
      config
    )
      .then((response) => {})
      .catch((error) => {}); // servidor de produção

    // Axios.post('http://localhost:8080/monitor-ws/email-portal/encaminharPrincipal', values, config)
    //     .then(response => { }).catch(error => { }) // homologação para testes
  };

  return (
    <Container fluid id="formulario" as="section">
      <ModalFeedback
        id="modal-section"
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <h3>fale conosco</h3>
      <Form.Text className="text-muted">
        A Statix não compartilha seus dados com terceiros.
      </Form.Text>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true); // pra desabilitar o botão de enviar
          setTimeout(() => {
            submitFunction(values); // enviando as coisas
            resetForm(); // resetando o formulário
            setSubmitting(false); // reabilitando o botão de enviar
            setModalShow(true); // mostrando o modal de feedback
          }, 500);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Col xl="4" md="12" sm="12" lg="4">
                  <Form.Group controlId="formBasicNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      name="nome"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nome}
                      className={
                        touched.nome && errors.nome ? "is-invalid" : null
                      }
                    />
                    <Form.Control.Feedback className="invalid-feedback">
                      {errors.nome}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xl="4" md="12" sm="12" lg="4">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Endereço de e-mail</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      className={
                        touched.email && errors.email ? "is-invalid" : null
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <Form.Control.Feedback className="invalid-feedback">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xl="4" md="12" sm="12" lg="4">
                  <Form.Group controlId="formBasicTel">
                    <Form.Label>Telefone (com DDD)</Form.Label>
                    <InputMask
                      mask="(99) 99999-9999"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telefone}
                    >
                      {(inputProps) => (
                        <Form.Control
                          {...inputProps}
                          type="text"
                          name="telefone"
                          className={
                            touched.telefone && errors.telefone
                              ? "is-invalid"
                              : null
                          }
                        />
                      )}
                    </InputMask>
                    <Form.Control.Feedback className="invalid-feedback">
                      {errors.telefone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xl="6" md="12" sm="12" lg="6">
                  <Form.Group controlId="formBasicArea">
                    <Form.Label>Razão Social</Form.Label>
                    <Form.Control
                      type="text"
                      name="razaoSocial"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.razaoSocial}
                      className={
                        touched.razaoSocial && errors.razaoSocial
                          ? "is-invalid"
                          : null
                      }
                    />
                    <Form.Control.Feedback className="invalid-feedback">
                      {errors.razaoSocial}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xl="6" md="12" sm="12" lg="6">
                  <Form.Group controlId="formBasicRazao">
                    <Form.Label>Área de atuação</Form.Label>
                    <Form.Control
                      type="text"
                      name="area"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.area}
                      className={
                        touched.area && errors.area ? "is-invalid" : null
                      }
                    />
                    <Form.Control.Feedback className="invalid-feedback">
                      {errors.area}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xl="12" md="12" sm="12" lg="12">
                  <Form.Group controlId="formBasicRazao">
                    <Form.Label>Mensagem (opcional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      type="text"
                      name="mensagem"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mensagem}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row xl={1} md={1} sm={12} lg={1}>
                <Col>
                  <Button
                    disabled={isSubmitting}
                    id="btn-submit"
                    variant="primary"
                    type="submit"
                  >
                    Enviar
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
