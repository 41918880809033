import React from 'react'
import { Link, animateScroll as Scroll } from 'react-scroll'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'

import Logo from '../../static/stx-logo.png'

const scrollToTop = () => {
    Scroll.scrollToTop()
}

const transition = 800
const offset = -90

export default function Header(){

    return (
        <React.Fragment>
            <Navbar as="header" fixed="top" collapseOnSelect expand="xl" id="stx-navbar">
                <Navbar.Brand onClick={scrollToTop}>
                    <img src={Logo} height="70" alt="Statix" />
                </Navbar.Brand>
                <Navbar.Toggle className="navbar-button" aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav as="nav" id="nav-list">
                        <Nav.Link as={Link} to="quem-somos" smooth={true} duration={transition} className="navbar-link text-nowrap">
                            quem somos
                        </Nav.Link>
                        <NavDropdown.Divider/>
                        <Nav.Link as={Link} to="servicos" smooth={true} duration={transition} offset={offset} className="navbar-link">
                            serviços
                        </Nav.Link>
                        <NavDropdown.Divider/>
                        <Nav.Link as={Link} to="parceiros" smooth={true} duration={transition} offset={offset} className="navbar-link">
                            parceiros
                        </Nav.Link>
                        <NavDropdown.Divider/>
                        <Nav.Link target="_blank" href="http://conciliador.statix.com.br/portal" className="navbar-link" id="conciliador-link">
                            acesso ao sistema
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    )
}